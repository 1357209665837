/* OfficeSpace.css */

.office-space-container {
  width: 100vw;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 2rem;
  margin-left: 5rem;
}

.text h1 {
  font-size: 1.6em;
  margin-bottom: 30px;
}

.office-image {
  width: 80%;
  height: auto;
  border-radius: 5px;
}

.text {
  font-size: 1.5em;
  color: #333;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.text p {
  font-size: 1.5rem;
}

@media (max-width: 1000px) {
  .office-space-container {
      flex-direction: column;
      margin-left: 0;
      gap: 20px;
  }

  .office-image {
      width: 90%;
      margin-left: 1rem;
  }

  .text {
      max-width: 90%;
      text-align: center;
      padding-right: 0;
     
  }
  .text p{
    padding: 0 2rem;
  }
}
