.review-section {
    margin: 1rem 3rem 2rem 3rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 45%;
  
  }
  
  .review-section h2{
    margin: 1rem 0 ;
    margin-bottom: 3rem;
  }
  .outerfeedback{
    width: 95vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .feedbackform{
    width: 45%;
    padding: 2rem 1rem;
  }
  @media (max-width: 1000px){
    .feedbackform{
      width: 100%;
      padding: 2rem 2rem;
      
      
    }
    h2{
      font-size: 2rem;
      padding: 1rem 2rem;
    }
    .review-section{
      max-width: 80%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: left;
      align-items: center;
      width: auto;
      max-width: 100%;
      /* padding: 1rem 2rem; */
      margin-left: 0;
    }
    .outerfeedback{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 3rem;
      height: auto;
      margin: 2rem 1rem;
    }
  }
