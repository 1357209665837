@import url('https://fonts.googleapis.com/css2?family=Jersey+20&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.single-flat {
  width: 20rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-decoration: none;
 
}

.single-flat img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.flat-name {
  margin: 2rem 1rem;
  text-decoration: none;
  color: black;
  font-family: "Poppins", sans-serif;
}

.inner-sections {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
}
.flat-details{
  margin-bottom: 1.5rem;
}
.flat-details p {
  font-size: 1rem;
  color: rgb(69, 69, 69);
  width: auto;
  margin: 0;
}

.flat-details b {
  font-weight: bold;
  margin: 0;
}
