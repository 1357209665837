.single-review {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 90%;
    padding: 1rem 1rem;
  }
  
  .profile-pic {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .review-details {
    flex: 1;
    font-size: 0.80rem;
    margin-left: 0.3rem;

  }
  .review-text{
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .review-name {
    font-weight: bold;
    margin-bottom: 3px;
   font-size: 1rem;
  }
  
  @media (max-width: 1000px){
    .single-review{
      width: 80vw;
      max-width: 100%;
      display: flex;
      flex-direction: row;
     
      
    }
    .review-details{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: auto;
      width: 100%;
    }
    .review-text{
      width: 100%;
      height: auto;

    }
  }