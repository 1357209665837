@import url('https://fonts.googleapis.com/css2?family=Jersey+20&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  margin-left: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100vw !important;
}
.App{
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
 
}

/* Styling for the outer container */
#outerouter {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

/* Styling for the upper main page */
.uppermainpg {
  font-size: 40px;
  color: rgb(42, 71, 165);
  font-weight: 700;
  width: 100%;
  height: 17vh;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
}

/* Styling for the lower main page */
.lowermainpg {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Footer styling */
footer {
  background-color: #734101;
  color: white;
  text-align: center;
  padding: 20px 0;
  flex-shrink: 0;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .uppermainpg {
    font-size: 30px;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .uppermainpg {
    font-size: 24px;
  }
}
@media (max-width: 1000px){
  .App{
    max-width: 100vw;
    justify-content: center;
    align-items: center;
  }
  *{
    max-width: 100vw !important;
  }

}
