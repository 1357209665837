.flat-section {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 20px;
  }
  
  .flat-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Additional styling can be added based on your design requirements */
  