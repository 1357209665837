@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700');
@import url('https://fonts.googleapis.com/css2?family=Sedan:ital@0;1&display=swap');

* {
  font-family: 'Open Sans';
}

.footer-section {
  width: 100%;
  display: inline-block;
  background: #734101;
  height: 30vh;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
  
  
}
section{
  text-align: center;
}

.footer-distributed {
  background: #734101;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 2rem sans-serif;
  padding: 1rem 2rem;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 35%;
}

/* The company logo */

.footer-distributed h3 {
  color: #e9b767;

  font: normal 2.5rem 'Open Sans', cursive;
  margin: 0;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #ffffff;
  margin: 1rem 0;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
}

.footer-distributed .footer-company-name {
  color: #222;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 40%;
}

.footer-distributed .footer-center i {
  background-color: #734101;
  color: #ffffff;
  font-size: 1.4rem;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.4rem;
  margin: 1rem 0.5rem;
  vertical-align: middle;
}
.footer-distributed .footer-center b{
  font-size: 1.4rem;
  margin: 3rem 1rem;
  line-height: 3;
  font-weight: 600;
  color: rgb(234, 237, 241);
 
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #ffffff;
  font-weight: 400;
  
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 2;
}

.footer-distributed .footer-center p a {

  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 1.3rem;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 0.5rem;

}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 20%;
}

/* .footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
} */

/* .footer-distributed .footer-company-about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
} */

.footer-distributed .footer-icons {
  margin: 1rem -1rem;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 2.3rem;
  height: 2.3rem;
  cursor: pointer;
  background-color: #734101;
  border-radius: 2px;
  font-size: 2.4rem;
  color: #ffffff;
  text-align: center;
  line-height: 2rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}
h3{
  font-family: "Sedan", serif;
font-weight: 400;
font-style: normal;
text-decoration: double;
}
.footer-location{
display: flex;
justify-content: center;

}
.footer-location i{
margin: 0.7rem 0.9rem 0.5rem 0.9rem;
font-size: 1.3rem;
}
.footer-location p{
  margin-top: 0.2rem;
  width: auto;
}
.footer-map{
margin-left: 0.9rem;
}
.footer-icons{
display: flex;
width: 50%;
justify-content: space-around;
}
.footer-section{
margin-bottom: 0px;
}
/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 1000px) {
  .footer-distributed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 95vw;
    padding: 1rem 1.5rem;
  }
  .footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    font-weight: 400;
    text-decoration: none;
    color: inherit;
    font-size: 1rem;
    margin-left: 0;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    width: 100%;
    text-align: center; /* Center text */
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
    margin-bottom: 0.2rem;
    margin-top: 2.5rem;
  }
  .footer-location i{
    margin: 0.7rem 0.5rem 0.5rem 0.5rem;
    font-size: 1.3rem;
    }
    
}
