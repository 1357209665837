@import url('https://fonts.googleapis.com/css2?family=Jersey+20&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sedan:ital@0;1&display=swap');

.header-rera{
    z-index: 200;
    width: 100vw;
    height: 0.1vh;
    /* height: 1.5rem; */
    font-size: 0.3rem !important;
    /* color: white;
    background: #945105; */
    color: #472601;
    background-color: white;
    text-align: center;
    font-family: "Sedan", serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    /* position: absolute;
    top: 1rem;
    left: auto; */
    /* padding: 1rem 1rem; */
    padding-bottom: 0px;
    position: sticky;
    top: 0;

}
.header-rera p{
    margin-top: 4rem;
    z-index: 2;
}
.navbar {
    background-color: white;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
    width: 100vw;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
    position: relative;
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.nav-logo {
    color: #8b5c15;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.6rem;
    flex-grow: 1;
    padding-bottom: 0.2rem;
    padding-left: 1rem;
    font-family: "Sedan", serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    align-items: center;
}

.nav-logo-text {
    display: flex;
    align-items: left;
    flex-direction: column;
    margin-top: 2rem;
    
    color: #8b5c15;
}

.nav-logo-text span {
    /* font-weight: bold; */
    font-size: 1.8rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    line-height: 1.3rem;
    
    color: #a65e0b;
}

.nav-logo-text p {
    color: #666;
    font-size: 0.8rem;
    text-align: left;
    font-weight: 500;
    margin-left: 0.1rem;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    margin-top: 1rem;
}

.nav-links {
    color: black;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: brown;
}

.nav-item .active {
    color: brown;
    border: 1px solid #ffdd40;
}

.nav-icon {
    display: none;
    color: #f5b921;
}

.nav-icon .icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
}

@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #1f5156;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color: #ffdd40;
        border: none;
    }

    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }

    .nav-logo .icon {
        width: 2rem;
        height: 2rem;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-100%, -50%);
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        color: #734101;
    }
}
/* Add this at the end of your existing CSS */

/* Add this at the end of your existing CSS */

.nav-icon {
    display: none;
  }
  
  .nav-icon i {
    cursor: pointer;
  }
  
  @media  (max-width: 1000px) {
    .nav-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      border-top: 1px solid #fff;
      position: absolute;
      top: 90px;
      left: 0;
      background-color: rgb(129, 69, 0);
      color: white;
      z-index: 1;
    }
  
    .nav-menu.active {
      display: flex;
      background-color: rgb(129, 69, 0);
      color: white;
    }
  
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #ddd; /* Add a separator between links */
      color: white;
    }
  
    /* .nav-logo {
      text-align: center;
    } */
  
    .nav-icon {
      display: flex; /* Change from block to flex */
      align-items: center; /* Center icon vertically */
      justify-content: flex-end; /* Align icon to the right */
      position: absolute;
      top: 0;
      right: 0.3rem;
      font-size: 1.4rem;
      color: black; /* Ensure icon is visible */
      z-index: 10; /* Ensure icon is above the menu */
      margin-top: 2.2rem;
      
    }
    .header-rera{
        z-index: 200;
        width: 100vw;
        height: 2vh;
        /* height: 1.5rem; */
        font-size: 0.1rem;
        /* color: white;
        background: #945105; */
        color: #472601;
        background-color: white;
        text-align: center;
        font-family: "Sedan", serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        /* position: absolute;
        top: 1rem;
        left: auto; */
        /* padding: 1rem 1rem; */
        padding-bottom: 0px;
        position: sticky;
        top: 0;
    
    }
    .header-rera p{
        margin: 1rem 1rem;
        margin-top: 4rem;
        margin-bottom: 3rem;
        z-index: 2;
        padding: 2rem 1rem;
    }
  }
  