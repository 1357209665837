.flat-detail {
  width: 80vw;
  margin: 50px auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 0.8rem !important;
  justify-content: center;
  align-items: center;
}

.detail-image {
  flex: 4; /* 60% of the total width */
}

.detail-image img {
  width: 100%;
  height: auto;
}

.detail-info {
  flex: 3; /* 40% of the total width */
  font-size: 0.8rem;
  margin-left: 5rem;
}

.detail-info h2 {
  margin-bottom: 1.2rem;
  font-size: 2rem;
}

.detail-address {
  font-weight: bold;
  margin-bottom: 5px;
}

.detail-text {
  margin: 2px 0;
  font-size: 1rem;
}

.detail-price {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 10px;
}

.detail-description {
  margin-top: 0px;
  font-size: 1rem;
}

.contact-buttons {
  
  margin-top: 2rem;
}

.contact-button {
  display: inline-block;
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-right: 2rem;
}

.call-button {
  background-color: #894e01;
}

.call-button:hover {
  background-color: #b66904;
}

.message-button {
  background-color: #894e01;
}

.message-button:hover {
  background-color: #b66904;
}

/* Media queries for responsiveness */
@media (max-width: 1000px) {
  .flat-detail {
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0;
  }

  .detail-image, .detail-info {
    width: 100%;
    margin-left: 0;
    
  }

  .detail-info {
    margin-left: 0;
    margin-right: 2rem;
  }

  .detail-info h2 {
    font-size: 2rem;
    margin-left: 2.3rem;
  }

  .detail-text {
    margin: 3px 0;
  }

  .detail-price {
    font-size: 0.8rem;
  }

  .detail-description {
    font-size: 0.8rem;
    padding: 0 2rem;
  }

  .contact-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .contact-button {
    font-size: 0.8rem;
    padding: 10px 30px;
    margin-left: 2.2rem;
  }
}
