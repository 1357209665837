.carouselText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30rem;
    color: rgb(0, 0, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure text is above the background */
    text-align: center;
    
}

.carouselText h1 {
    font-size: 60px;
    font-weight: 500;
    margin: 0; /* Remove default margin */
    opacity: 1; /* Set full opacity for text */

}

.carouselText h4 {
    width: 100%;
    font-size: 20px;
    font-weight: 400;
    margin: 0; /* Remove default margin */
    opacity: 1; /* Set full opacity for text */
    margin-top: 1.5rem;
    
}

.carouselContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-item {
    width: 100vw;
    height: 90vh;
    margin-top: 10px;
    position: relative;
    background-color: rgb(172, 130, 117);
    isolation: isolate;
    overflow: hidden; /* Prevent background from overflowing */
}

.carousel-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4; /* Adjust opacity for the background images */
    background-size: cover;
    background-position: center;
}

#carousel1::after {
    background-image: url("https://www.hamaribaat.com/wp-content/uploads/2017/02/mumbai-night-life.jpg");
}

#carousel2::after {
    background-image: url("https://c4.wallpaperflare.com/wallpaper/1011/101/300/apartment-condo-design-home-wallpaper-preview.jpg");
}

#carousel3::after {
    background-image: url("https://i.pinimg.com/736x/3d/da/e8/3ddae894b9812e1c65aef67ccf2c648a.jpg");
}
@media(max-width: 600px) {
    .carouselText h1 {
        font-size: 3rem;
        font-weight: 500;
        margin: 0; /* Remove default margin */
        opacity: 1; /* Set full opacity for text */
    }
    #carousel1 h1{
        font-size: 2rem;
    }
    .carouselText h4 {
        width: 100%;
        font-size: 1rem;
        font-weight: 400;
        margin: 0; /* Remove default margin */
        opacity: 1; /* Set full opacity for text */
        margin-top: 1.5rem;
    }
    .carouselContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 40vh;
        margin-bottom: 2rem;
    }
    
    
}
@media(max-width: 1300px) {
    .carouselContainer{
        max-height: 40vh;
        margin-bottom: 2rem;
        
    }
    #carousel1 h1{
        font-size: 2rem;
      
    }
    .carouselContainer h1, h4{
        text-align: left;
    }
    #carousel1::after {
        background-image: url("https://www.hamaribaat.com/wp-content/uploads/2017/02/mumbai-night-life.jpg");
      
    }
    
    #carousel2::after {
        background-image: url("https://c4.wallpaperflare.com/wallpaper/1011/101/300/apartment-condo-design-home-wallpaper-preview.jpg");
      
    }
    
    #carousel3::after {
        background-image: url("https://i.pinimg.com/736x/3d/da/e8/3ddae894b9812e1c65aef67ccf2c648a.jpg");
       
    }
    #carousel1, #carousel2, #carousel3{
        max-height: 50vh;
    }
}