.outerabout {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    margin: 1rem 10rem;
    margin-top: 1rem;
    margin-left: 10rem;
  }
  
  #firstabout, #secondabout, #thirdabout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 2rem 0;
  }
  
  .leftabout {
    width: 40%;
    text-align: left;
    margin-right: 6rem;
  }
  
  h2 {
    font-size: 2.5rem;
  }
  
  .rightabout {
    width: 40%;
  }
  
  .rightabout img {
    max-width: 100%;
    height: auto;
  }
  #secondabout img{
    width: 24rem;
    height: auto;

  }
  #secondabout p{
    margin-left: -1rem;
  }
  p{
    width: 28rem;
  }
  p{
    font-size: 1.2rem;
    line-height: 2.3rem;
  
  }
  img{
    border-radius: 0.2rem;
    width: 24rem;
    height: auto;
  }

  @media (max-width: 990px) {
    .outerabout {
      margin: 4rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      margin-left: 5rem;
    }
    
    
    #firstabout, #secondabout, #thirdabout {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 1rem 2rem;
      text-align: center;
    }
    #secondabout {
      display: none;
    }
    .leftabout, .rightabout {
      width: 80%;
      text-align: center;
     
    }
    .leftabout p, .rightabout p {
      width: 100%;
      text-align: center;
     
    }
  
    .leftabout h2 {
      font-size: 2rem;
    }
    .outerabout{
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
    }
    .about-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
    }
  
    .leftabout img,
    .rightabout img {
      width: 90%;
      height: auto;
      margin: 1rem 0rem;
    
       /* Add some spacing between image and text */
    }
  
    .leftabout p,
  .rightabout p {
      margin: 0;
      font-size: 0.9rem;
      text-align: center;
      padding-left: 5rem;
      line-height: 1.8rem;
      margin: 1rem 0rem;
    }
    img{
      margin-right: 2rem;
    }
 
  }

 
@media (max-width: 700px){
  .outerabout{
    width: 100vw;
    margin: 2rem 0rem;
    /* margin-left: 5rem; */
  }
  .about-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
  }

  
      img{
        margin-right: 2rem;
      }
}
